import './css/work.css';
import { Link as RouterLink } from 'react-router-dom';

import React, { useState, useEffect } from "react";
import axios from '../config/index';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';



function Work(){

    const [works, setWorks] = useState({});



    const getAllItems = () => {
        axios.post('AllItems', { table: 'poem' })
            .then((res) => {
                if (res.data) {
                    setWorks(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };



    useEffect(() => {
        getAllItems();
    }, []);


    return(
        <>
            <section id="portfolio" className='work'>
                <div className='container'>
                    <div className='work-content'>
                        <div className='work-header'>
                            <div className='left'>
                                <div className='buttons'>
                                    <button data-aos="fade-in" data-aos-delay="100" className='prev-btn'><i class="las la-angle-left"></i></button>
                                    <button data-aos="fade-in" data-aos-delay="200" className='next-btn'><i class="las la-angle-right"></i></button>
                                </div>

                                <div className='desc'>
                                    <p data-aos="fade-in" data-aos-delay="300">
                                    الدكتورة بدرية عبد الله البشر تتمتع بمجموعة من المهارات المتنوعة التي أهلتها لتكون شخصية بارزة في العديد من المجالات، بما في ذلك الأدب، الإعلام، والبحث الأكاديمي. إليك بعض المهارات الرئيسية:
                                    </p>
                                </div>

                            </div>

                            <h1 data-aos="fade-in" data-aos-delay="500">بعض المؤلفات <span> </span></h1>

                        </div>


                        <div data-aos="fade-in" data-aos-delay="600" className='work-slider'>
                            <Swiper grabCursor={true} spaceBetween={50} slidesPerView={'auto'} navigation={{
                                nextEl: '.next-btn',
                                prevEl: '.prev-btn',
                                }} pagination={false}
                                    autoplay={{
                                        delay: 6000,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[Autoplay,Navigation,Pagination]}
                                    >

                                    {works.length ? (
                                        works.map((item) => {
                                            return (
                                        <SwiperSlide>
                                            <RouterLink to={`/poam?id=${item.id}`}>
                                                <div className='work-ele'>
                                                    <img src={`/uploads/${item?.img_1}`} alt='غلاف الرواية'/>
                                                    <div className='overlay'>
                                                        <h1> {item?.name} </h1>
                                                        <h2>{item?.date} </h2>
                                                    </div>
                                                </div>
                                            </RouterLink>
                                            </SwiperSlide>

                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={5}>تحميل...</td>
                                        </tr>
                                    )}

                                    </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Work;