import './css/story.css';
import React, { useState, useEffect } from "react";
import axios from '../config/index';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function Story(){
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const [formInputs, setFormInputs] = useState({});
    const getItemData = (itemId) => {
        axios.post('get-item', { itemId, table: "poem" })
            .then((res) => {
                if (res.data) {
                    setFormInputs(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    

    useEffect(() => {
        getItemData(id);
        scrollToTop();
    }, []);


    return(
        <>
            <section className='page-header'>
                <img src={`/uploads/${formInputs?.img_1}`} alt={formInputs?.name} />
                <div className='text'>
                    <h1> {formInputs?.name} </h1>
                </div>
            </section>

            <div className='story-content'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='story-image'>
                                <img src={`/uploads/${formInputs?.img_1}`} alt={formInputs?.name} />
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='story-text'>
                                <ul>
                                    <li>
                                        <span> اسم الرواية </span>
                                        {formInputs?.name} 
                                    </li>


                                    <li>
                                        <span> المؤلف </span>
                                        {formInputs?.creatorname} 
                                    </li>

                                    <li>
                                        <span> تاريخ الإنشاء </span>
                                        {formInputs?.date} 
                                    </li>
                                </ul>

                                <span className='line'></span>



                                <a href={formInputs?.link}  target='_blank'> تحميل المؤلفة </a>

                                <div className='text'>
                                    <h1> {formInputs?.name}  </h1>

                                    <p> 
                                    {formInputs?.para} 
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Story;