import './css/navbar.css';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import React, { useState } from 'react';


function Navbar(){

    // State to manage the visibility of the floating nav
    const [isActive, setIsActive] = useState(false);

    // Function to open the floating nav
    const openNav = () => {
        setIsActive(true);
    };

    // Function to close the floating nav
    const closeNav = () => {
        setIsActive(false);
    };


    
    return(
        <>
        <nav>
            <div className='container'>
                <div className='nav-content'>
                <button type='button' aria-label='فتح القائمة' className='open-float-nav' onClick={openNav}>
                        <i class="las la-bars"></i> 
                </button>
                </div>
            </div>
        </nav>

        <div className={`float-nav ${isActive ? 'active' : ''}`}>
            <div className='float-nav-content'>
                <button className='close-float-nav' aria-label='غلق القائمة' onClick={closeNav}>
                    <i className="las la-times"></i>
                </button>
                <ul>
                <li>
                            <RouterLink to="/" smooth={true} duration={500} onClick={closeNav}>
                                الرئيسية
                            </RouterLink>
                        </li>
                        <li>
                            <ScrollLink to="about" smooth={true} duration={500} onClick={closeNav}>
                                عني
                            </ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to="portfolio" smooth={true} duration={500} onClick={closeNav}>
                                أعمالي
                            </ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to="experience" smooth={true} duration={500} onClick={closeNav}>
                                خبراتي
                            </ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to="blog" smooth={true} duration={500} onClick={closeNav}>
                                المدونة
                            </ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to="contact" smooth={true} duration={500} onClick={closeNav}>
                                تواصل معي
                            </ScrollLink>
                        </li>


                </ul>
            </div>
        </div>
        </>
    )
}

export default Navbar;