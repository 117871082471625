import './css/landing.css';

import React, { useState, useEffect } from "react";
import axios from '../config/index';



function Landing(){

    const [formInputs, setFormInputs] = useState({});
    const [socials, setSocials] = useState({});





    const getItemData = (itemId) => {
        axios.post('get-item', { itemId, table: "landing" })
            .then((res) => {
                if (res.data) {
                    setFormInputs(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllItems = () => {
        axios.post('AllItems', { table: 'social' })
            .then((res) => {
                if (res.data) {
                    setSocials(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };



    useEffect(() => {
        getItemData(1);
        getAllItems();
    }, []);


    return(
        <>
            <div className='landing'>
                <div className='landing-content'>
                    <div className='text'>
                        <div className='top'>
                            <h2 data-aos="fade-in" data-aos-delay="100"> {formInputs?.secheader} </h2>
                                <h1 data-aos="fade-in" data-aos-delay="200"> {formInputs?.header}  </h1>
                                <p data-aos="fade-in" data-aos-delay="300">
                                    {formInputs?.para}
                                </p>
                        </div>

                        <div className='bottom'>
                            <div className='socials'>
                                <a data-aos="fade-in" data-aos-delay="400" href={socials[2]?.link} target='_blank'>
                                    <i class="lab la-facebook-f"></i>
                                </a>

                                <a data-aos="fade-in" data-aos-delay="500"  href={socials[1]?.link} target='_blank'>
                                    <i class="lab la-twitter"></i>
                                </a>

                                <a data-aos="fade-in" data-aos-delay="600"  href={socials[0]?.link} target='_blank'>
                                    <i class="lab la-linkedin-in"></i>
                                </a>

                            </div>

                            <a data-aos="fade-in" data-aos-delay="700"  href={`tel:${socials[4]?.link}`} target='_blank'>{socials[4]?.link}</a>
                            <a data-aos="fade-in" data-aos-delay="800" href={`mailto:${socials[3]?.link}`} target='_blank'>{socials[3]?.link}</a>

                        </div>
                    </div>
                    

                    <div data-aos="fade-in" data-aos-delay="100" className='image'>
                        <img data-aos="fade-in" data-aos-delay="100" src={`/uploads/${formInputs?.img_1}`} alt='صورة لدكتور بدرية' />
                    </div>
                </div>
            </div>
        </>
    )
    
}

export default Landing;

