import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef,useEffect } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Navbar from './components/Navbar';
import Landing from './components/Landing';
import About from './components/About';
import Work from './components/Work';
import Experience from './components/Experience';
import Clients from './components/Clients';
import Blogs from './components/Blogs';
import Contact from './components/Contact';
import Footer from './components/Footer';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Story from './components/Story';
import Blog from './components/Blog';




// Admin Pages


import SideNav from "./components/Admin/SideNav";
import LoginAdmin from "./components/Admin/LoginAdmin";


import SearchBar from "./components/Admin/SearchBar";
import Dashboard from "./components/Admin/Dashboard";
import AllUsersPage from "./components/Admin/AllUsersPage";
import EditUser from "./components/Admin/EditUser";
import AddUser from "./components/Admin/AddUser";


import AllSocialPage from "./components/Admin/AllSocialPage";
import EditSocial from "./components/Admin/EditSocial";


import AllBlogs from './components/Admin/AllBlogs';
import EditBlog from './components/Admin/EditBlog';
import AddBlog from './components/Admin/AddBlog';
import ManageLanding from './components/Admin/ManageLanding';
import ManageAbout from './components/Admin/ManageAbout';
import ManagePoem from './components/Admin/ManagePoem';
import ManageExp from './components/Admin/ManageExp';
import ManageClient from './components/Admin/ManageClient';
import ManageBlogs from './components/Admin/ManageBlogs';
import ManageSocial from './components/Admin/ManageSocial';

function App() {

    const [sessionExists, setSessionExists] = useState("");
    const servicesRef = useRef(0);
    const location = useLocation();
    const hideHeaderFooter =
    location.pathname === "/dashboard" ||
    location.pathname === "/all-users" ||
    location.pathname === "/all-waiting-users" ||
    location.pathname === "/edit-user" ||
    location.pathname === "/add-user"|| 
    location.pathname === "/all-abouts"||
    location.pathname === "/edit-abouts"||
    location.pathname === "/all-socials"||

    location.pathname === "/all-landings"||
    location.pathname === "/edit-landings"||

    location.pathname === "/all-poems"||
    location.pathname === "/edit-poems"||
    location.pathname === "/add-poems"||

    
    location.pathname === "/all-exps"||
    location.pathname === "/edit-exps"||
    location.pathname === "/add-exps"||

    
    location.pathname === "/all-clients"||
    location.pathname === "/edit-clients"||
    location.pathname === "/add-clients"||

    location.pathname === "/all-blogs"||
    location.pathname === "/edit-blogs"||
    location.pathname === "/add-blogs"||

    location.pathname === "/edit-socials";





    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1500,
        });
    }, [])


return (
    <div className="App">

<SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        />


            <Routes>

            
            <Route path="/" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <Landing />
                        <About />
                        <Work />
                        <Experience />
                        <Clients />
                        <Blogs />
                        <Contact />
                        <Footer  className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />


            <Route path="/poam" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <Story />
                        <Contact />
                        <Footer  className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />

                
            <Route path="/blog" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <Blog />
                        <Contact />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />











                
            <Route path="/admin-login"element={<><LoginAdmin sessionExists={sessionExists} setSessionExists={setSessionExists} /></>}/>
            <Route path="/dashboard"element={ <><Dashboard /> </>}/>
            <Route path="/all-users" element={ <><AllUsersPage /> </>}/>
            <Route path="/edit-user" element={<><EditUser /></>} />
            <Route path="/add-user" element={<><AddUser /></>}/>
            <Route path="/all-users"element={<><AllUsersPage /></> }/>

            <Route path="/all-socials" element={<><ManageSocial /></> }/>
            <Route path="/edit-socials" element={<><ManageSocial /></> }/>

            <Route path="/all-abouts" element={<><ManageAbout /></> }/>
            <Route path="/edit-abouts" element={<><ManageAbout /></> }/>


            <Route path="/all-landings" element={<><ManageLanding /></> }/>
            <Route path="/edit-landings" element={<><ManageLanding /></> }/>


            <Route path="/all-poems" element={<><ManagePoem /></> }/>
            <Route path="/edit-poems" element={<><ManagePoem /></> }/>
            <Route path="/add-poems" element={<><ManagePoem /></> }/>

            <Route path="/all-exps" element={<><ManageExp /></> }/>
            <Route path="/edit-exps" element={<><ManageExp /></> }/>
            <Route path="/add-exps" element={<><ManageExp /></> }/>

            <Route path="/all-clients" element={<><ManageClient /></> }/>
            <Route path="/edit-clients" element={<><ManageClient /></> }/>
            <Route path="/add-clients" element={<><ManageClient /></> }/>

            <Route path="/all-blogs" element={<><ManageBlogs /></> }/>
            <Route path="/edit-blogs" element={<><ManageBlogs /></> }/>
            <Route path="/add-blogs" element={<><ManageBlogs /></> }/>


        </Routes>



        
    </div>
);
}

export default App;
