import './css/footer.css';

function Footer(){
    return(
        <>
            <footer>
                <div className='container'>
                    <div className='footer-content'>
                        <h1> جميع الحقوق محفوظة &copy; <span> د. بدرية عبد الله البشر </span></h1>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;