import './css/about.css';
import React, { useState, useEffect } from "react";
import axios from '../config/index';

function About(){

    const [formInputs, setFormInputs] = useState({
        header: "",
        secheader: "",
        para1: "",
        para2: "",
        images: null,
    });
    const [AllItems, setAllItems] = useState([]);


    
    const getAllItems = () => {
        axios.post('AllItems', { table: 'exp' })
            .then((res) => {
                if (res.data) {
                    setAllItems(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };


    
    const getItemData = (itemId) => {
        axios.post('get-item', { itemId, table: "about" })
            .then((res) => {
                if (res.data) {
                    setFormInputs(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData(1);
        getAllItems();
    }, []);


    return(
        <>
            <section id="about" className='about'>
                <div className='about-content'>
                    <div data-aos="fade-in" data-aos-delay="100" className='image'>
                        <img src={`/uploads/${formInputs.img_1}`} alt='صورة دكتور بدرية'/>
                    </div>

                    <div className='text'>
                        <h1 data-aos="fade-in" data-aos-delay="200" > {formInputs?.header}  <span> </span> </h1>

                        <h2 data-aos="fade-in" data-aos-delay="300" > {formInputs?.secheader} </h2>


                        <p data-aos="fade-in" data-aos-delay="400" > 
                        {formInputs?.para1}

                        </p>

                        <p data-aos="fade-in" data-aos-delay="500" >
                        {formInputs?.para2}

                        </p>

                        <ul>
                            {AllItems.length ? (
                                AllItems.map((item) => {
                                    return (
                                        <li data-aos="fade-in" data-aos-delay="100" >
                                            {item?.header}
                                        </li>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}>تحميل...</td>
                                </tr>
                            )}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;