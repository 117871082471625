import './css/blog.css';

import React, { useState, useEffect } from "react";
import axios from '../config/index';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function Blog(){
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    
    const [formInputs, setFormInputs] = useState({});
    const getItemData = (itemId) => {
        axios.post('get-item', { itemId, table: "blog" })
            .then((res) => {
                if (res.data) {
                    setFormInputs(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    

    useEffect(() => {
        getItemData(id);
        scrollToTop();

    }, []);




    return(
        <>
            <section className='blog-header'>
                <div className='overlay'></div>

                <img src={`/uploads/${formInputs?.img_1}`} alt='اسم المدونة' />
                <div className='text'>
                    <h1>  {formInputs?.header} </h1>

                    <ul>
                        <ul> 
                            <li>
                                <i class="las la-pen"></i>
                                {formInputs?.creatorname} 
                            </li>

                            <li>
                                <i class="las la-folder-open"></i>
                                مدونة
                            </li>

                            <li>
                                <i class="las la-tags"></i>

                                {formInputs?.words} 
                            </li>
                        </ul>
                    </ul>
                </div>
            </section>


            <section className='blog-content'>
                <div className='container'>
                    <div className='text'>
                        <p>
                        {formInputs?.para} 
                        </p>

                        <h2>  
                        {formInputs?.secheader} 
                        </h2>

                        <p>
                        {formInputs?.para2} 
                        </p>

                        <img src={`/uploads/${formInputs?.img_1}`} alt='اسم المدونة' />
                    </div>
                </div>
            </section>
        </>
    )
}
export default Blog;