import './css/dashboard.css';
import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function Dashboard(){


    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    const [clientsCount, setclientsCount] = useState(0);
    const [workCount, setworkCount] = useState(0);
    const [BlogsCount, setBlogsCount] = useState(0);
    const [ExpCount, setExpCount] = useState(0);

    const [works, setWork] = useState(0);

    

    const getAllWork = ()=>{
        axios.post('AllItems', {
            table: 'poem',
        })
        .then((res) => {
            if (res.data) {
                setWork(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
                navigate('/dashboard');
            }else{
                // navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);




    const getClientsCount = () => {
        axios.post('get-item-count', {
            table: 'client',
        })
        .then((res) => {
            if (res.data) {
                setclientsCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };



    
    const getWorkCount = () => {
        axios.post('get-item-count', {
            table: 'poem',
        })
        .then((res) => {
            if (res.data) {
                setworkCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };



    
    const getBlogsCount = () => {
        axios.post('get-item-count', {
            table: 'blog',
        })
        .then((res) => {
            if (res.data) {
                setBlogsCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };


    
    
    const getExpCount = () => {
        axios.post('get-item-count', {
            table: 'exp',
        })
        .then((res) => {
            if (res.data) {
                setExpCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };


    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }

    
useEffect(() => {
    getAllWork();


    getBlogsCount();
    getClientsCount();
    getExpCount();
    getWorkCount();

    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>
                <div className='stats-content'>
                    <Link to="/all-blogs">
                        <div className='stat'>
                            <i className="las la-users"></i>
                            <div className='info'>
                                <h1>{BlogsCount}</h1>
                                <h2> المقالات </h2>
                            </div>
                        </div>
                    </Link>
                    

                    <Link to="/all-clients">
                        <div className='stat'>
                        <i className="las la-question"></i>
                            <div className='info'>
                                <h1>{clientsCount}</h1>
                                <h2> العملاء  </h2>
                            </div>
                        </div>
                    </Link>


                    <Link to="/all-exps">
                        <div className='stat'>
                        <i className="las la-poll-h"></i>
                            <div className='info'>
                                <h1>{ExpCount}</h1>
                                <h2> المهارات </h2>
                            </div>
                        </div>
                    </Link>

                    <Link to="/all-poems">
                        <div className='stat'>
                        <i className="las la-poll-h"></i>
                            <div className='info'>
                                <h1>{workCount}</h1>
                                <h2> المؤلفات </h2>
                            </div>
                        </div>
                    </Link>

                    
                </div>




                <div className='results-table'>

                <table>

                    <thead>
                        <tr>
                        <th> # </th>
                            <th> الصورة </th>
                            <th> الأسم </th>
                            <th> اسم المؤلف </th>
                            <th> التاريخ </th>
                            <th> الإختيارات </th>

                        </tr>
                    </thead>
                    <tbody>

                    {works.length ? (
                    works.map((work) => {
                        return (
                        <tr key={work.id}>
                            
                            <td>
                            <h6>{work.id}</h6>
                            </td>

                            <td><img src={`./uploads/${work.img_1}`} alt="item Image"/></td>

                            <td>
                            <h6>{work.name}</h6>
                            </td>

                            <td>
                            <h6>{work.creatorname}</h6>
                            </td>

                            <td>
                            <h6>{work.date}</h6>
                            </td>


                            <td><Link className='edit' to={`/edit-poems?id=${work.id}`}> <i class="las la-edit"></i> </Link></td>

                        </tr>
                        );
                    })
                    ) : (
                    <tr>
                        <td colSpan={5}>Loading...</td>
                    </tr>
                    )}

                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default Dashboard;