import './css/blogs.css';
import { Router, Link as RouterLink } from 'react-router-dom';



import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';

import React, { useState, useEffect } from "react";
import axios from '../config/index';



function Blogs(){
    
    const [items, setItems] = useState({});
    const getAllItems = () => {
        axios.post('AllItems', { table: 'blog' })
            .then((res) => {
                if (res.data) {
                    setItems(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllItems();
    }, []);




    return(
        <>
            <section id="blog" className='blogs'>

                <div className='overlay'></div>


                <div className='container'>
                    <div className='header'>
                        <div className='buttons'>
                            <button data-aos="fade-in" data-aos-delay="100" className='prev-btn'><i class="las la-angle-left"></i></button>
                            <button  data-aos="fade-in" data-aos-delay="200" className='next-btn'><i class="las la-angle-right"></i></button>
                        </div>

                        <div className='text'>
                            <p data-aos="fade-in" data-aos-delay="300">
                            نقدم لكم مجموعة من المقالات التي تبرز أفكار وأصوات كتّاب متميزين يناقشون موضوعات متنوعة تشمل الثقافة، الاجتماع، والتحولات العالمية
                            </p>
                        </div>


                        <h1 data-aos="fade-in" data-aos-delay="500"> المقالات <span></span></h1>
                    </div>


                    <div data-aos="fade-in" data-aos-delay="600" className='blogs-slider'>
                        
                    <Swiper grabCursor={true} spaceBetween={50} slidesPerView={'auto'} navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            }} pagination={false}
                                autoplay={{
                                    delay: 6000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay,Navigation,Pagination]}
                                >


                                            
                            {items.length ? (
                                items.map((item) => {
                                    return (
                                        
                                        <SwiperSlide>
                                            <div className='blog'>
                                                <RouterLink to={`/blog?id=${item?.id}`}>
                                                    <h1>  {item?.header} </h1>
                                                </RouterLink>

                                                <div>
                                                    <h2><i class="las la-clock"></i>  
                                                    {item?.date} 
                                                    </h2>

                                                    <h2> <i class="las la-pen-alt"></i> 
                                                    {item?.creatorname} 
                                                    </h2>
                                                </div>

                                                <p>
                                                    {(item.para)?.slice(0, 380)}...
                                                </p>
                                            </div>
                                        </SwiperSlide>

                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}>تحميل...</td>
                                </tr>
                            )}



                            </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blogs;