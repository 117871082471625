import './css/experience.css';
import React, { useState, useEffect } from "react";
import axios from '../config/index';


function Experience(){
    const [items, setItems] = useState({});
    const getAllItems = () => {
        axios.post('AllItems', { table: 'exp' })
            .then((res) => {
                if (res.data) {
                    setItems(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllItems();
    }, []);




    return(
        <>
            <div id="experience" className='experience'>
                <div className='container'>
                    <div className='experience-content'>
                        <div className='header'>
                            <h1 data-aos="fade-in" data-aos-delay="100"> المهارات <span></span> </h1>

                            <p data-aos="fade-in" data-aos-delay="200">
                                الدكتورة بدرية عبد الله البشر تمتلك مجموعة واسعة من المهارات المتقدمة التي جعلت منها شخصية مؤثرة ومتعددة الأدوار في المجتمع الثقافي والأكاديمي. إليك وصفًا أكثر تفصيلًا لبعض هذه المهارات:
                            </p>
                        </div>

                        <div className='all-exp'>

                            {items.length ? (
                                items.map((item) => {
                                    return (
                                        <div className='exp'>
                                        <div data-aos="fade-in" data-aos-delay="400" className='left'>
                                            <h2> {item?.header} </h2>
                                            <p>
                                            {item?.para}
                                            </p>
                                        </div>
        
                                        <div data-aos="fade-in" data-aos-delay="300" className='right'>
                                            <h3> {item?.secheader} </h3>
        
                                            <div>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}>تحميل...</td>
                                </tr>
                            )}


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Experience;