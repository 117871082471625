import './css/contact.css';

import React, { useState, useEffect } from "react";
import axios from '../config/index';


function Contact(){

    
    const [socials, setSocials] = useState({});



    const getAllItems = () => {
        axios.post('AllItems', { table: 'social' })
            .then((res) => {
                if (res.data) {
                    setSocials(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };



    useEffect(() => {
        getAllItems();
    }, []);




    return(
        <>
            <section id="contact" className='contact'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                            <div className='contact-form'>
                                <form>
                                    <div data-aos="fade-in" data-aos-delay="100" className='input'>
                                        <input placeholder='الإسم بالكامل' required type='text' />
                                    </div>

                                    <div data-aos="fade-in" data-aos-delay="200" className='input'>
                                        <input placeholder='البريد الإلكتروني' required type='email' />
                                    </div>


                                    <div data-aos="fade-in" data-aos-delay="300" className='input'>
                                        <input placeholder='الموضوع' required type='text' />
                                    </div>

                                    <div data-aos="fade-in" data-aos-delay="400" className='input'>
                                        <textarea placeholder='الرسالة' required ></textarea>
                                    </div>

                                    <button data-aos="fade-in" data-aos-delay="500" type='submit'> إرسال الرسالة </button>
                                </form>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='contact-text'>
                                <h1 data-aos="fade-in" data-aos-delay="100"> تواصل معنا الان</h1>

                                <ul>
                                    <li data-aos="fade-in" data-aos-delay="200">
                                        <a href={socials[5]?.link}>
                                        {socials[5]?.name}
                                            <i class="las la-street-view"></i>

                                        </a>
                                    </li>

                                    <li data-aos="fade-in" data-aos-delay="300">
                                        <a href={`tel:${socials[3]?.link}`}>
                                        {socials[4]?.link}
                                            <i class="las la-phone-volume"></i>

                                        </a>
                                    </li>


                                    <li data-aos="fade-in" data-aos-delay="400">
                                        <a href={`mailto:${socials[3]?.link}`}>
                                        {socials[3]?.link}
                                            <i class="las la-envelope"></i>

                                        </a>
                                    </li>


                                </ul>

                                <div className='socials'>
                                    <a data-aos="fade-in" data-aos-delay="100" href={socials[2]?.link} target='_blank'>
                                        <i class="lab la-facebook-f"></i>
                                    </a>

                                    <a data-aos="fade-in" data-aos-delay="200" href={socials[1]?.link} target='_blank'>
                                        <i class="lab la-twitter"></i>
                                    </a>

                                    <a data-aos="fade-in" data-aos-delay="300" href={socials[0]?.link} target='_blank'>
                                        <i class="lab la-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;