
import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';


import './css/clients.css';
import React, { useState, useEffect } from "react";
import axios from '../config/index';

function Clients(){


    const [items, setItems] = useState({});
    const getAllItems = () => {
        axios.post('AllItems', { table: 'client' })
            .then((res) => {
                if (res.data) {
                    setItems(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllItems();
    }, []);



    return(
        <>
            <section className='clients'>
                <div className='container'>
                    <div className='clients-header'>
                        <h1 data-aos="fade-in" data-aos-delay="100"> أبرز الأسماء <span></span></h1>
                    </div>


                    <div data-aos="fade-in" data-aos-delay="200" className='clients-slider'>
                        <div className='buttons'>
                            <button className='prev-btn'><i class="las la-angle-left"></i></button>
                            <button className='next-btn'><i class="las la-angle-right"></i></button>
                        </div>

                        <Swiper grabCursor={true} spaceBetween={50} slidesPerView={'auto'} navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            }} pagination={false}
                                autoplay={{
                                    delay: 6000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay,Navigation,Pagination]}
                                >

                                    
                            {items.length ? (
                                items.map((item) => {
                                    return (
                                        <SwiperSlide>
                                            <div className='client'>
                                                <h2> {item?.name} </h2>
                                            </div>
                                        </SwiperSlide>

                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}>تحميل...</td>
                                </tr>
                            )}
                    
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Clients;